import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import "./CPListTab.scss";
import "../../../App.js";
import { GetCommon } from "../../../resources/image/Image";
import CPBottomModal from "../bottomModal/CPBottomModal";
import { useModal } from "../../context/ModalContext";

CPListTab.propsTypes = {
  data: PropTypes.array,
  firstTabOnClick: PropTypes.func,
  firstTabType: PropTypes.string,
  firstTabTitle: PropTypes.string,
  renderItem: PropTypes.any,
  containerStyle: PropTypes.any,
  todayOnClick: PropTypes.func,
  yesterdayOnClick: PropTypes.func,
  last7OnClick: PropTypes.func,
  last15OnClick: PropTypes.func,
  last30OnClick: PropTypes.func,
  calender: PropTypes.bool,
  container: PropTypes.any,
};

CPListTab.defaultProps = {
  data: [
    {
      item: "listing 1",
    },
  ],
  title: "Title",
  onClick: () => alert("list tab"),
  todayOnClick: () => alert("today"),
  yesterdayOnClick: () => alert("yesterday"),
  last7OnClick: () => alert("last 7"),
  last15OnClick: () => alert("last 15"),
  last30OnClick: () => alert("last 30"),
  calender: true,
};

export function Tab({ title, onClick, type, style }) {
  return (
    <div className={`tab-list-btn ${style}`} onClick={onClick}>
      <div className={type ? "tab-list-txt-selected" : "tab-list-txt-unSelect"}>
        {title}
      </div>
      {type && (
        <div
          className="tab-list-underline-selected"
          style={{ width: title.length * 11 }}
        />
      )}
    </div>
  );
}

export default function CPListTab({
  data,
  firstTabOnClick,
  firstTabType,
  firstTabTitle,
  containerStyle,
  todayOnClick,
  yesterdayOnClick,
  last7OnClick,
  last15OnClick,
  last30OnClick,
  renderItem,
  calender,
  container,
}) {
  var bottom = document.getElementById("transaction_modal");
  window.onclick = function (event) {
    if (event.target === bottom) {
      setDateVisible("none");
    }
  };

  const { createBottomModal, clearBottomModal } = useModal();
  const { t: trans } = useTranslation();
  const Common = GetCommon();

  const today = trans("general.list.today"),
    yesterday = trans("general.list.yesterday"),
    last7 = trans("general.list.last7"),
    last15 = trans("general.list.last15"),
    last30 = trans("general.list.last30"),
    all = trans("game.all");

  const [dateVisible, setDateVisible] = useState("none");
  const [selected, setSelected] = useState(last30);

  function DateBtn({ title, btnStyle, onClick }) {
    return (
      <div
        className={`datePicker-btn ${
          title === selected && "datePicker-selected"
        }`}
        style={btnStyle}
        onClick={() => {
          onClick();
          setDateVisible("none");
          clearBottomModal();
        }}
      >
        {title}
      </div>
    );
  }

  return (
    <div className={`tab-list-container ${container}`}>
      <div className="tab-list-style">
        <Tab
          title={firstTabTitle ? firstTabTitle : all}
          type={firstTabType}
          onClick={firstTabOnClick}
          style={"tab-all"}
        />
        <div
          className={`tab-rest-container ${containerStyle} ${
            calender ? "tab-rest-container-with-calendar" : ""
          }`}
          style={{
            marginRight: calender ? 10 : 0,
            // width: calender ? "60%" : "100%",
          }}
        >
          {data.map((item, index) => {
            return renderItem(item, index);
          })}
        </div>
        {calender ? (
          <div
            className="tab-date-container"
            onClick={() => {
              createBottomModal({
                background: "black",
                bottomModalStyle: "datePicker-style",
                insideCloseBtn: true,
                content: (
                  <div className="datePicker-container">
                    <div className="datePicker-title">
                      {trans("general.list.filterTitle")}
                    </div>
                    <div className="datePicker-top-container">
                      <DateBtn
                        title={today}
                        onClick={() => {
                          setSelected(today);
                          todayOnClick();
                        }}
                      />
                      <DateBtn
                        title={yesterday}
                        onClick={() => {
                          setSelected(yesterday);
                          yesterdayOnClick();
                        }}
                      />
                      <DateBtn
                        title={last7}
                        onClick={() => {
                          setSelected(last7);
                          last7OnClick();
                        }}
                      />
                    </div>
                    <div className="datePicker-top-container">
                      <DateBtn
                        title={last15}
                        btnStyle={{ width: "48%" }}
                        onClick={() => {
                          setSelected(last15);
                          last15OnClick();
                        }}
                      />
                      <DateBtn
                        title={last30}
                        btnStyle={{ width: "48%" }}
                        onClick={() => {
                          setSelected(last30);
                          last30OnClick();
                        }}
                      />
                    </div>
                  </div>
                ),
              });
            }}
          >
            <img
              alt="datePicker-icon"
              src={Common.calender}
              className="datePicker-icon"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
