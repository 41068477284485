import { clientInfo } from "..";

export default {
  general: {
    title: {
      welcome: "Welcome",
      welcome2: "Sign Up / Login",
      welcomeTo: "Welcome To",
      signUp: "Sign Up",
      register: "Registration",
      verifyOtp: "Verify OTP",
      topUp: "Decimal Deposit",
      bankDetail: "Top Up - Bank Detail",
      withdraw: "Withdraw",
      transfer: "Credit Transfer",
      bankList: "Bank List",
      trxnList: "Transaction List",
      trxnDetail: "Detail",
      gameRec: "Game Record",
      gameComm: "Game Commission",
      affiliate: "Affiliate",
      notification: "Notifcation",
      profile: "My Profile",
      cs: "Customer Service",
      newBankAcc: "New Bank Account",
      enterPin: "Enter Passcode",
      newPin: "New PIN",
      confirmPin: "Confirm New PIN",
      forgotPin: "Reset Pin",
      forgotPass: "Reset Password",
      fund: "Fund Transfer",
      mission: "Mission",
      downline: "Downline",
      comm: "Commission",
      learnMore: "Learn More",
      web: "Website",
      premium: "Become Premium Agent",
      movie: "Movie",
      sportTV: "Sport TV",
      sportStreaming: "Sport Streaming",
      liveScore: "Live Score",
      UEFA: "Champions League Preiction",
      porn: "Porn",
      chat: "Chat",
      avatar: "Avatar",
      commSummary: "Commission Summary",
      deposit: "Deposit",
      autoDeposit: "Automatic Deposit",
      changePass: "Change Password",
    },

    btn: {
      help: "Need help?",
      verify: "Verify",
      reSend: "Re-send",
      tryAgain: "Try again",
      login: "Login",
      signOut: "Sign Out",
      forgotPin: "Forgot Pin?",
      needHelp: "Need Help?",
      next: "Next",
      ok: "Ok",
      cancel: "Cancel",
      delete: "Delete",
      submit: "Submit",
      add: "Add",
      play: "Play",
      checkStatus: "Check Status",
      tempPin: "Get Temporary Pin",
      changePass: "Change Password",
      changePin: "Change Pin",
      back: "Back",
      save: "Save",
      confirm: "Confirm",
      withdraw: "Withdraw",
      topup: "Top Up",
      transBtn: "Transfer",
      inviteBtn: "Invite Now",
      gotIt: "Got It",
      openBank: "Open Bank App",
      home: "Home",
      reloadBtn: `Click here, if you can't fast forward the video`,
      closeAll: "Close All",
      downloadApp: "Download App",
      update: "Update",
    },

    label: {
      fromBank: "From Account",
      transferFrom: "Transfer From",
      toBank: "To Account",
      refCode: "Referral Code",
      verifyCode: "Verification Code",
      amt: "Enter Amount",
      minAmt: "Minimum Deposit 10",
      accName: "Account Name",
      bankAccName: "Bank Account Name",
      bank: "Bank",
      accNo: "Account No",
      totalBal: "Total Balance",
      totalComm: "Commission",
      detail: "Details",
      gameInfo: "Game Info",
      mobileNo: "Mobile No.",
      status: "Status",
      dateTime: "Date / Time",
      type: "Type",
      to: "To",
      from: "From",
      commAmt: "Commission Amount",
      chooseCountry: "Choose Your Country",
      chooseLanguage: "Choose Your Language",
      backToTop: "Top",
      currentPin: "Current PIN",
      newPin: "New PIN",
      confirmPin: "Confirm New PIN",
      totalRebate: "Total Rebate:",
      totalRebateEarn: "Lifetime rebate:",
    },

    placeholder: {
      selectBank: "Select a bank",
      entPrefAmt: "Please enter preferred amount",
      enterAcctNo: "Enter your account no",
      enterAcctName: "Enter Account name",
      enterAmt: "Please enter amount",
      selectType: "Select Type",
      mobileNumber: "Mobile number",
      password: "Password",
      searching: "Searching",
      mobileOrPin: "Password / 6 Digits Pin Number",
      email: "Email Address",
      ngad: "NGAD_SEO",
      enterMobile: "Enter Your Mobile Number",
      enterName: "Enter Your Name",
      autoName: "Name (Auto)",
      currentPw: "Current Password",
      trueAccName: "Enter Your True Account Name",
      trueAccNo: "Enter Your True Account No",
      confirmTrueAccNo: "Confirm Your True Account No",
      selectTrueAcc: "Select Your Account",
      pleaseSelect: "Please Select",
      googleAuth: "Google Authenticator",
      enterNewPw: "Enter Your New Password",
      confirmNewPw: "Confirm Your New Password",
    },

    dropdown: {
      walletToBank: "Wallet -> Bank",
      commToWallet: "Commission -> Wallet",
      commToBank: "Commission -> Bank",
    },

    pin: {
      enterPin: "Enter Pin",
      setPin: "Set Pin",
      newPin: "New Pin",
      confirmPin: "Confirm Pin",
      tempPin: "Temporary Pin",
    },

    list: {
      noRecord: "No Records Found",
      noNotif: "No Notifications",
      pull: "Pull to refresh",
      filterTitle: "Transaction history up to 30 days only",
      filterBy: "Filter By",
      filterGame: "Filter Game Name",
      today: "Today",
      yesterday: "Yesterday",
      last7: "Last 7 days",
      last15: "Last 15 days",
      last30: "Last 30 days",
      typeTopUp: "Top Up",
      typeTrans: "Transfer",
      typeWith: "Withdraw",
      typeGame: "Game",
      success: "Success",
      reject: "Reject",
      pending: "Pending",
      complete: "Complete",
      approved: "Approved",
      processing: "Processing",
      win: "Win",
      lose: "Lose",
      draw: "Draw",
      choosePeriod: "Choose Period",
      thisWeek: "This Week",
      thisMonth: "This Month",
      lastMonth: "Last Month",
    },

    misc: {
      update: "Updating ...",
      update2: "Initializing ...",
      appNotLatest:
        "App is not latest, please update your app to continue from iOS download link",
      appVer: "Ver :",
      scan: "Scan",
      nativeUpdate:
        'New Version\nPlease reinstall the app to get latest version\nStep 1: Delete current app\nStep 2: Press "Install" button',
    },

    months: {
      jan: "Jan",
      feb: "Feb",
      mar: "Mar",
      apr: "Apr",
      may: "May",
      jun: "Jun",
      jul: "Jul",
      aug: "Aug",
      sep: "Sep",
      oct: "Oct",
      nov: "Nov",
      dec: "Dec",
    },

    errMsg: {
      fieldRequired: "This field is required.",
      pwNotMatch: `Password doesn't match`,
      phoneFormat: `Incorrect Phone Number Format`,
      pwFormat: `Please input minimum 6 characters`,
      emailFormat: `Incorrect Email Format`,
      countryCode: "Please select the country code.",
      invalidAccNum: "Invalid Account Number",
      bankExists: "Bank account already exists. Please use another.",
      accNumNotMatch: `Account No doesn't match`,
      trueAmtRange:
        "Please ensure the amount falls within the range of 10 to 5000 Baht.",
      pwThreeSameCharac: "Password cannot have three same characters in a row",
      pwUpperCase: "Password must contain at least one uppercase letter",
      pwLowerCase: "Password must contain at least one lowercase letter",
      pwNumFormat: "Password must contain at least one number",
    },
  },

  dialog: {
    btn: {
      ok: "Ok",
      cancel: "Cancel",
      exit: "Exit",
      retry: "Retry",
      yes: "Yes",
      no: "No",
      done: "Done",
      edit: "Edit",
      update: "Update",
      missionComp: "Complete",
      install: "Install",
      play: "Play",
    },

    msg: {
      updateLatest: "Please update to latest version",
      appExit: "Are you sure you want to exit app ?",
      topUpOk:
        "Top up will be processed once the amount is banked in.\n\nNotfication will send you once it is completed.",
      topUpAsk: "Are you sure you want to cancel top up?",
      topUpCancel: "Top up cancel successfully",
      topUpSuccess: "Top up successfully",
      withdraw:
        "Withdraw submitted successfully.\n\nThe process might take up to 15 minutes to complete.",
      gameExit: "Are you sure you want to exit ?",
      qrSave: "QR code saved successfully",
      changePass: "Successfully Changed Password",
      timeUp: `Time's up !\nTransaction Expired`,
      transSucess: "Transfer Successfully",
      witSuccess:
        "Withdraw submitted successfully.\n\nThe process might take up to 15 minutes to complete.",
      commSuccess: "Commission withdraw successfully.",
      topProcess:
        "Top Up is in process.\n\nWithdraw is not available until top up is completed.",
      sessionExp: "Session expired.",
      verifyBank: `Verify bank account in progress,\nPlease come back later.`,
      successful: "Successful !",
      missionComp: "Mission Completed",
      claimReward: "Please come back after claiming your reward.",
      gameInProgress: "Game In Progress",
    },

    err: {
      maintainence: `We're down for maintenance. \nBe right back\n04:00 PM (GMT+ 7)`,
      timeout: "Timeout Error, Please try again later",
      networkFail: "Network request failed, please try again.",
      someErr: "Something went wrong, please try again later.",
      pinMax: "Maximum 6 digits only",
      permission: "Permission ",
      qrFailed: "Failed to save QR code ",
      wrongPass: "Wrong Password",
      passRequire: "Password does not match the requirements",
      pin: "New Pin & Confrim Pin\n are not same",
      amtMax: "Maximum 5 digits only",
      amtZero: "Amount cannot start from zero",
      amtEmpty: "Please enter amount",
      insufficient: "Insufficient balance",
      noBank: "No bank found, please add bank (Bank List)",
      minAmt: "Minimum amount",
      verifyBank:
        "Unable to verify bank, please add a valid bank account number",
      angpao: "Angpao claimed within 24 hour only (Expired)",
      depositMaintenance: "Deposit service is under maintenance",
      witMaintenance: "Withdrawal service is under maintenance",
      tryAgain: "Please try again",
      lineLogin: "LINE login error",
      gameNotFound: "Games Not Found!!!",
      gameNotFoundDesc: `{{appGame}} APP not detected Please download {{appGame}} APP using button below and click "Play" after installation completed.`,
      downloadLinkErrDesc:
        "Download link is currently unavailable, please try again later.",
      userNotExist: "User does not exist",
      gameNotAvailable: "Promotion detected, game not available at the moment.",
      somethingWrong:
        "Something went wrong, Please contact your administrator for help.",
    },
  },

  toast: {
    resetPass: "Reset Password successfully",
    setPin: "Set Pin successfully",
    copyAccName: "Copied Account Name",
    copyAccNum: `Bank Account Number\nCopied to Dashboard`,
    copyAmt: `Amount\nCopied to Dashboard`,
    status: "Status : ",
    updateErr: "Update error, please restart the application",
    updateBal: "Balance Update",
  },

  bankAccName: {
    title: `Bank account name is required for wallet related features (eg. Withdraw)`,
    important: `Important:\nYour bank account name cannot be changed once it is confirmed.`,
    understand: `I understand.`,
  },

  bankAccNum: {
    title: `To offer you the full experiences, your bank account name is require. It will be used for Wallet related features(eg. Withdraw)`,
    important: `Important:\nYour bank account name cannot be changed once it is confirmed.`,
  },

  signIn: {
    line: "Chat With Us",
    lang: "Language",
    or: "Or",
    loginLine: "Login with LINE",
    login: "Login",
    register: "Register",
  },

  verifyMobile: {
    verifyTitle: "Verify Your Phone Number",
    otpSent: "OTP sent to mobile no above.",
    resendIn: "Re-send code in: ",
  },

  signUp: {
    ref: {
      tips1: `A referer is required for registration.`,
      tips2: `Scan their QR code or enter their referral code to continue registration.`,
      tips3: `You are referred by:`,
      tips4: `Scan their QR code or enter their referral code.`,
    },
    otp: {
      tips1: `OTP has been sent to the mobile no below:`,
      tips2: `\n\nPlease enter the code with the same prefix shown below.`,
      tips3: "Re-send code in",
    },
    err: {
      refCode: `Invalid referral code`,
    },
    pwd: {
      tips: "Password requirement :",
      min: "Minimum",
      max: "Maximum",
      char: "characters",
      pwd: "password",
      cpwd: "confirm password",
      request: "Request",
      verify: "Verify",
      otp: "OTP",
      check: "Check",
      skip: "Skip",
      ref: "Referer Code",
    },
  },

  forgotPin: {
    msg: {
      1: `We will send you the temporary pin to the below mobile number.`,
      2: `\n\nPlease click the button to get temporary pin.`,
    },
    title: {
      tempPin: "Temporary Pin",
    },
  },

  forgotPass: {
    label: {
      newPass: "New Password",
      confirmPass: "Confirm Password",
    },
  },

  homepage: {
    act: {
      title: `Explore ${clientInfo.appname}`,
      more: "More",
      topUp: "Top Up",
      fund: "Fund",
      withdraw: "Withdraw",
      transfer: "Transfer",
      bankList: "Bank List",
      trxnList: "Transaction",
      gameRec: "Game Record",
      gameComm: "Commission",
      affiliate: "Affiliate",
      notification: "Notification",
      profile: "My Profile",
      cs: "Help",
      mission: "Mission",
      chat: "Chat",
      downline: "My Downline",
      learnMore: "Learn More",
      web: "Website",
      premium: "Become Premium Agent",
      feedback: "Feedback",
      language: "Language",
      club: "Club",
      home: "Home",
      game: "Game",
      reward: "Reward",
      vip: "VIP",
    },
    wheel: {
      title: "Fortune Wheel Bonanza",
      desc: `Every valid deposit of 100 baht above is eligible to participate in the Fortune Wheel Bonanza event for 1 time!\n\nSpin your wheel to win a random bonus of up to 100% with a turnover of x1 only.\n\nNo other hidden terms & conditions.`,
      claimMsg_1: "You won",
      claimMsg_2: "of",
      turnover: "Turnover",
      noTurnover: "(No Turnover)",
    },
    promo: {
      header: "CONGRATULATIONS BOSS!",
      body: `Now! Spread the joy with your friends so they can get angpao too! Once your friend become your downline, you'll get commission when they plays!`,
      body2: `Currently many winners are claiming angpao\nYou'll be receiving angpao in a while!`,
      share: "Share",
      receive: "receive",
      time: "CHECK AGAIN BY",
      note: "Note: Redeem angpao within 24 hrs.",
    },
    activity: {
      title: "Angpao coming again ! Make your friends love you !",
      decs: `Invite your friend to join ${clientInfo.appname}. We'll reward your friend with a random Angpao on your behalf. Total value 2,000,000 baht when registering. First come, first serve! Invite your friends to join right now!`,
    },
    intro: {
      next: "Next",
      end: "Play & Earn Now",
      card_1: {
        title: "Everyone is Boss",
        desc: `Play + Earn with ${clientInfo.appname}. First in the market, one app to play PG, Joker, UFA and many more. Earn passive income with mission, rebate and commission.`,
      },
      card_2: {
        title: "Extra Fun",
        desc: "High unlimited rebate everyday for all your favourite games in one place.",
      },
      card_3: {
        title: "Earn passive income with ease",
        desc: "Share with your friends to earn unlimited commission forever whether your friends win or lose.",
      },
      card_4: {
        title: "Never get bored with mission",
        desc: "Earn exciting rewards anytime. Just by solving few simple mission.",
      },
      card_5: {
        title: "Easy to play",
        desc: "Fully automated system, fast & reliable. No min deposit & withdrawal conditions is needed.",
      },
    },
    news: {
      cancel: "Cancel",
      news_1: {
        title: `${clientInfo.appname} is back to normal!`,
        desc_1:
          "We apologize for all the inconvenience caused. We have encountered serious malicious attack from various parties that disrupted all our services since last night.",
        desc_2:
          "Our team work effortlessly to prevent and resolve the situation. We are proud to announce that everything has resumed back to normal. You may now deposit and withdrawal normally.",
        desc_3: `Thank you for your patience. ${clientInfo.appname} aim to serve you better.`,
        desc_4: "#everyoneisaboss",
      },
      news_2: {
        title: "Check In Bonus",
        desc: `Login to ${clientInfo.appname} every 1st & 15th of the month to claim free reward.\n\nCheck out mission for more excitement.`,
        goto: "Go to Mission",
      },
      news_3: {
        title: "Time Limited Discount for Top Up",
        desc: `Receive full amount to play but paying less for it. Guaranteed no extra conditions when you perform withdrawal. Deposit now to enjoy our special time-limited discounts. `,
        goto: "Top Up Now",
      },
      rebate: {
        title: "100% Rebate for your deposit for one time",
        desc: `Your deposit is automatically protected within 24 hours for one time!\nWhen you win, you take all the winnings\nWhen you lose, you are guaranteed with 100% rebate\n\n100% rebate is valid for deposit amount up to 5000 THB (maximum)\nYour 100% rebate will be distributed daily for 10 days.\nClaim your rebate everyday here before it expired`,
        cusTitle: `NOTE:`,
        cus_1: "- Mission is only valid for one time",
        cus_2:
          "- 100% rebate is only valid when you lost your first time deposit within 24 hours",
        cus_3:
          "- In the event that your deposit is not lost within 24 hours, or any withdrawal performed, the mission will not be valid",
        cus_4:
          "- Any unclaimed rebates will be automatically forfeited after 24 hours",
      },
      UEFA: {
        title: "UEFA EURO 2020 CHAMPIONSHIP PREDICTION",
        desc: `Guess the champion and win huge prize pool. Predict and pick the team that you think is going to win the UEFA EURO 2020. Prize pool of 10,000,000 THB is shared among all participants who correctly predicted the winning team of UEFA EURO 2020.\n\nGet an additional chance to predict by inviting 1 friend with successful registration if your current predicted team did not make it to Round of 16.\n\nGet an additional chance again to predict by inviting 3 friends with successful registration if your current predicted team did not make it to Quarter-finals.\n\nThe earlier you participate in the mission, the higher share of the prize pool you can get upon correct prediction.`,
      },
    },
  },

  game: {
    games: "Games",
    all: "All",
    sports: "Sports",
    ecasino: "E-Casino",
    slot: "Slot",
    fish: "Fish",
    // cockfight: 'Cock Fight',
    funky: "Funky",
    table: "Table",
    poker: "Poker",
    arcade: "Arcade",
    live: "Live",
    bingo: "Bingo",
    lottery: "Lottery",
    club: "Club",
    hot: "Hot",
    new: "New",
    sort: "A-Z",
    fav: "Fav",
    noGame: "No Game",
    loading: "Loading ...",
    label: {
      all: "All Games",
      sports: "Sport Games",
      ecasino: "E-Casino Games",
      slot: "Slot Games",
      fish: "Fish Games",
      cockfight: "Cock Fight Games",
      funky: "Funky Games",
      table: "Table Games",
      poker: "Poker Games",
      arcade: "Arcade Games",
      live: "Live Games",
      bingo: "Bingo Games",
      club: "Club",
    },
    allProvider: "All provider",
    others: "Others",
    searchGame: "Search Game Name",
    provider: "Provider",
    search: "Search",
    providerDesc: {
      pg: "Experience amazing gameplay and rewards with PG Soft's diverse range of cutting-edge slot games.",
      joker:
        "Dive into non-stop excitement and endless thrills with Joker's captivating online slots.",
      jili: "Unleash your winning potential with Jili's immersive games and cutting-edge technology.",
      mp: "Indulge in unparalleled entertainment with Microgaming's award-winning hot games and stunning graphics.",
      wssport:
        "Elevate your sports betting experience with WS Sport's comprehensive platform and competitive odds.",
      saba: "Immerse yourself in thrilling sports betting with Saba Sports' outstanding solutions.",
      pp: "Enjoy innovation and entertainment with Pragmatic Play's captivating casino games.",
      fc: "Enter a world of prosperity and fortune with Fa Chai's stunning games for diverse player preferences.",
      km: "Embark on a journey to wealth with Kingmaker's engaging games, filled with captivating and enticing rewards.",
      spade:
        "Explore unforgettable adventures with visually stunning slots, enticing themes, and rewards by Spade Gaming.",
      haba: "Elevate your betting experience to the next level with Habanero's revolutionary slot games, which present cutting-edge features and advanced gameplay.",
      rt: "Achieve massive victories in the breathtaking slot games by Red Tiger, showcasing state-of-the-art graphics and captivating rewards.",
      yl: "Enjoy the limitless possibilities with Youlian Gaming's unique concepts and immersive gameplay.",
      sexy: "Indulge in the allure of Sexy Baccarat's glamorous live casino games, a perfect blend of elegance and excitement.",
      venus:
        "Uncover gaming excellence at Venus Casino, where you'll find exhilarating games and heavenly rewards.",
      sv388:
        "Immerse in the electrifying world of cockfighting betting with SV388, a seamless platform for enthusiasts.",
      e1: "Feel the thrill of victory with E1 Sport which specializes in immersive sports betting.",
      pt: "Enter a limitless fun world with Playtech, known for cutting-edge technology and engaging gameplay across genres.",
      fastspin:
        "Fuel your adrenaline with Fastspin's high-quality, fast-paced casino games.",
      luckypoker:
        "Dive into the realistic card games at Lucky Poker, with a variety of game options, tournaments, and the most lucrative rewards.",
      sbo: "Bet with confidence at Sbobet, the top provider of sports betting games and live casinos, offering unmatched excellence.",
      yesbingo:
        "Enjoy exciting Bingo variants, patterns, and generous prizes at Yes Bingo, a popular provider for global bingo enthusiasts.",
      pussy:
        "Prepare to be immersed in stunning visuals, seamless gameplay, and incredible jackpots offered by Pussy888's captivating slot games.",
      kiss918:
        "Stunning graphics, intuitive controls, and exciting bonuses make 918Kiss stand out as the most popular mobile slot provider.",
      mega888:
        "Experience thrilling slots and live casino action with Mega888, offering immersive visuals, substantial rewards, and mega wins.",
    },
    info: {
      line: "Lines",
      rtp: "RTP",
      volatility: "Volatility",
      betLimit: "Bet Limit",
      maxWin: "Max Win",
      feature: "Feature",
      ways: "Ways",
      credit: "Credit",
      cluster: "Cluster",
    },
  },

  club: {
    title: "All Complete Entertainment",
  },

  movie: {
    imdb: "IMDb",
    play: "PLAY",
    rottenTomatoes: "Rotten Tomatoes",
    metacritic: "Metacritic",
    googleUsers: "Google users",
    likeThis: "liked this film",
    releaseDate: "Release date: ",
    directors: "Directors: ",
    boxOffice: "Box office: ",
    budget: "Budget: ",
    awards: "Awards: ",
    all: "All",
    hot: "Hot",
    recent: "Recent",
  },

  sportTv: {
    watchNow: "Watch Now",
    live: "Live",
  },

  UEFA: {
    modalConfirm: "Confirm predict this country ?",
    confirm: "Confirm predict this country",
    choose: "Choose a Country",
    active: "Active",
    inactive: "Inactive",
    predicted: "Country Predicted",
  },

  feedback: {
    title: `Enjoying ${clientInfo.appname} ?`,
    tapDesc: `Tap a star to rate ${clientInfo.appname}.`,
    howDesc: "How would you like us to improve ?",
    eg: `eg: I Love ${clientInfo.appname}...`,
    submit: "Submit",
  },

  deposit: {
    active: "Active",
    inactive: "Inactive",
    auto: {
      title: "Automatic Deposit",
      desc: "Fully automated deposit within 1 minute",
    },
    decimal: {
      title: "Decimal Deposit",
      desc: "Transfer with exact decimal for deposit within 3 minutes",
    },
  },

  autoDeposit: {
    1: " 1. Transfer to the bank account below with your registered bank",
    2: " 2. System will automatically process your deposit within 1 minute",
    label: {
      step: "Step: ",
    },
  },

  topUp: {
    tips: {
      //1: `Step 1:\nPlease key in any amount for Topup.\nCountdown timer starting from 3 minutes.`,
      //2: `\n\nStep 2:\nPlease copy Bank Account Name and Account No.`,
      //3: `\n\nStep 3:\nGoto own bank\nPlease paste it the detail for make a transfer.`,
      confirm: "Got it",
      hide: `Don't show again`,
      howToDeposit: "How To Deposit",
      1: `Key in any amount for topup in 3 minutes.`,
      2: `Copy bank account details.`,
      3: `Paste details into your own bank & transfer.`,
    },

    label: {
      amtTxt: "Enter your preferred amount",
      countdown: "Countdown Timer",
      bank: "Bank:",
      accName: "Account Name:",
      accNum: "Account No:",
      step1: `Step 1: `,
      step2: `Step 2: `,
      step3: `Step 3: `,
      remark: "Please transfer exact amount above including decimal.",
      remark2:
        "Your wallet will be updated within 2 mins upon successful transaction.",
      report: "Report a problem",
      lineBank: "User bank: ",
      lineBankAcct: "User Account Number: ",
      lineAmount: "Amount: ",
      amount: "Amount",
    },

    promo: {
      title: "Reminder for decimal deposit:",
      1: "1. Transfer the exact amount including the decimal number.",
      2: "2. DO NOT round up the amount.",
      3: "3. After submitting the deposit request, deposit within 10 mins.",
      4: "4. Resubmit the request if the transaction was not completed in 10 mins.",
      promo:
        "Pay less & get full amounts, No addtional withdrawal conditions for discount claimed.",
      get: `Amount You'll Get`,
      title_2: "Random Bonus (NEW)",
      content:
        "Complete deposit to redeem FREE random bonus from 0% to 100% with TURNOVER X1 ONLY !!! ",
    },
  },

  withdraw: {
    label: {
      bankAcc: "Bank Account",
      witTxt: "* Amount can be withdrawn up to ",
      YourWal: "Your Wallet",
      YourComm: "Your Commissions",
      balNotEnuf: "* Balance not enough",
      minWit: "* Minimum amount",
      turnover:
        "* Please wait 10 minutes if. your turnover is not yet proccessed",
    },
  },

  transfer: {
    transTxt: "Amount can be transfered up to ",
    BankTxt: "Withdraw to Bank",
    WalTxt: "Transfer to Wallet",
  },

  bankList: {
    noBank: "No Bank Account",
    deleteMsg: "Do you want to delete this bank?",
    changeName: "Account name successfully changed!",
  },

  fundTransfer: {
    btn: {
      transaction: "Transaction",
      gameRec: "Game Record",
      bankDetails: "Bank Details",
      details: "Details",
    },
    title: {
      depositStatus: "Deposit In Progress",
    },
    card: {
      wdrlBal: "Withdrawable Balance",
      turnover: "Turnover Remaining",
    },
    tips: {
      title: "Pro Tips",
      tips: "Get unlimited 0.3% rebate for every hand you played. Rebate earned will be credited into your commission wallet everyday.",
    },
  },

  commDetails: {
    tips: {
      title: "Pro Tips",
      tips: `Earn lucrative passive income (Commission) by playing games, inviting friends, or completing in-game mission.\nLearn how to earn commission now.`,
      learnMore: "- Learn More -",
    },
  },

  gameRec: {},

  gameComm: {},

  affiliate: {
    friend: "Invite Friend",
    save: "Save QR Code",
    downlineList: "Downline",
    reconnectRef: "Reconnect Referrer",
    successReconnect: "Successfully Reconnect",
    learnMore: "Learn More",
    lifetime: "Lifetime Income: ",
    unsettleComm: "Unsettled Commission:",
    consolidate: "Consolidate on: ",
    url: "Your Affiliate URL: ",
    shareNow: "Share Now",
    shareLine: "Share to Line",
    copy: "Copy Affiliate Link",
    report: "Commission Report",
    level: "Level",
    summary: "Summary",
    totalRegister: "Total Register",
    totalNewDeposit: "Total New Deposit",
    totalActiveDepositor: "Total Active Depositor",
    totalWinLost: "Total Win/Lost",
    totalPromotionalCost: "Total Promotional Cost",
    totalRoyaltiesCost: "Total Royalties Cost",
    totalCost: "Total Cost",
    totalCostDesc: "missions, promotions, royalties, etc. from your downline",
    totalCommission: "Total Commission",
  },

  notfication: {},

  profile: {
    choose: "Choose your profile",
  },

  cs: {},

  chat: {
    tab: {
      info: "Info",
      LINE: "LINE",
      Chat: "Chat",
    },
    line: {
      title: "Chat with us in LINE",
      btn: "Chat Now",
      mobileNum: "Mobile No:",
    },
  },

  lock: {
    witLockTitle: `Status:\nWithdraw is in process...`,
    witLockMg: `Press "Check Status Button"\nwhen withdraw is completed`,
    lockTitle: `Status:\nYour account has been locked`,
    lockMg: `Press "Help Button"\nfor contact our customer service.`,
  },

  news: {
    title: "News",
    btn: "Dismiss",
  },

  codepush: {
    new: "New version is available",
  },

  mission: {
    moreInfo: "Click for more info",
    learnMore: "Learn More",
    inProgress: "In Progress",
    claimed: "Claimed",
    claimNow: "Claim Now",
    expiry: "Activity Expiry: ",
    status: "Activity Status: ",
    progress: "Activity Progress: ",
    active: "Active",
    requirement: "Requirement:",
    turnover: "Turnover:",
    pending: "Pending",
    go: "Go",
    filter: {
      all: "All",
      inProgress: "In Progress",
      completed: "Completed",
      record: "Record",
    },
  },

  downline: {
    all: "All",
    new: "New",
    active: "Active",
    inactive: "Inactive",
    lastActive: "Last Active: ",
    social: {
      call: "Call",
      sms: "SMS",
      line: "Line",
      chat: "Chat",
    },
  },

  info: {
    label: {
      hide: `Don't show again`,
    },
    commDetail: {
      title: "Commission Wallet",
      desc_1:
        "Your commission will reflect your latest commission earned. This includes rebates, mission rewards, earnings from your downline's turnover.",
      desc_2:
        "- You may transfer your commission to your wallet once you've accumulated",
      desc_2_amt: "100 THB.",
      desc_3:
        "- You may withdraw your commission directly to your bank account once you've accumulated",
      desc_3_amt: "2000 THB.",
    },
    fundTransfer: {
      title: "Main Wallet",
      desc: "Your wallet will reflect your latest balance based on your winnings or losings. All the funds you deposit will be reflected under your wallet. When you perform any withdrawal, the fund will be deducted from your wallet.",
    },
    mission: {
      title: "Mission",
      desc: "Every completed mission will earn you free rewards. Rewards are credited into your commission wallet. Tap on each mission to see more details about the mission.",
    },
    affiliate: {
      title: "Share your referral code",
      decs: "Start earning in 5 simple steps.",
      step_1: "Step 1: ",
      step_1_desc:
        "Share your invitation code or QR code or invitation link with your friends",
      step_2: "Step 2: ",
      step_2_desc: "Your friends sign up and start playing",
      step_3: "Step 3: ",
      step_3_desc: `You will get 10% of your friends' win-loss as your earnings`,
      step_4: "Step 4: ",
      step_4_desc: `Help your friend to recruit players and you will get 3% of their friends' win-loss as your earnings`,
      step_5: "Step 5: ",
      step_5_desc: `Get 1% of win-loss from 3rd-tier downlines`,
      remark:
        "Turnover is your total bet amount. You get earnings whether it is winning or losing bets! You get 0.3% of your turnover automatically. Start recruiting to get the additional 10%, 3%, and 1% from your network of friends now.",
    },
    depositProgress: {
      title: "Deposit In Progress",
      desc: "Our system process deposit within 1 minute. Please contact our customer service if your deposit is pending for more than 15 minutes.",
    },
    downline: {
      title: "Downline",
      desc: "Manage your direct downlines by checking their recent activity. Nurture and build your own network empire and start earnings uncapped lifetime commission.",
    },
    howToPlay: {
      title: "How To Play",
      step_1: "เร็วสุด ๆ",
      step_1_desc:
        "ลงทะเบียนด้วย LINE ID หรือ เบอร์โทรศัพท์ของคุณ ได้ใน 10 วินาที",
      step_2: "เร็วยิ่งขึ้น",
      step_2_desc:
        "รับเงินฝากของคุณผ่าน ระบบอัตโนมัติใน 2 วินาที หลังจากการโอนเงิน",
      step_3: "โบนัสจัดหนัก",
      step_3_desc:
        "รับสิทธิ์หมุนวงล้อนําโชค ลุ้นโบนัสสุ่มแจกสูงสุด 100%ทุกครั้งที่ฝากเงิน",
    },
  },

  err: {
    catch: `9999 : Opps somehting went wrong, please try again later`,
    //#region #1

    // Login, Param, Database
    WRONG_PASS: "Invalid mobile or password", // 'Wrong password',
    MOBILE_NOT_EXIST: "Invalid mobile or password", //' Mobile not exist',
    WRONG_PIN: "Invalid pin",
    DOMAIN_NOT_REGISTER: "Domain not register",
    RECORD_NOT_FOUND: "Invalid mobile or password", // 'Record not found',

    SYSTEM_MAINTENANCE: "System is under maintenance",

    // Authenticate
    KEY_EXPIRE: "Connection Error", // 'Key expired',
    KEY_INVALID: "Connection Error", // 'Invalid Key',
    SIGN_INVALID: "Connection Error", // 'Invalid Signature',
    TOKEN_INVALID: "Connection Error", // 'Invalid Token',
    SIGN_EMPTY: "Connection Error", // 'Empty Signature',
    TOKEN_EMPTY: "Connection Error", // 'Empty Token',
    SYSTEM_ERROR: "System Error",
    ACTION_INVALID: "Invalid Action",
    DUPLICATED_REQUEST: "Please try again later.", // request token

    // Param
    DID_MISS: "Missing DID",
    SECRET_MISS: "Missing secret",
    COMPCODE_MISS: "Missing company code",
    USERID_MISS: "Missing user ID",

    MOBILE_MISS: "Missing Mobile",
    MOBILE_SHORT: "Mobile too short",
    MOBILE_NUMERIC: "Mobile numeric onlu",
    MOBILE_NO_PLUS: "Mobile no plus symbol",

    GAME_USERID_EMPTY: "Empty Game UserID",
    GAME_USERID_LONG: "Game UserID too long",

    OTP_MISS: "Missing OTP",
    PASS_MISS: "Missing password",
    PIN_MISS: "Missing pin",
    REF_MISS: "Missing referral code",
    SESSIONID_MISS: "Missing session ID",
    OLDPASS_MISS: "Missing current password",
    OLDPIN_MISS: "Missing current pin",
    BANK_CODE_MISS: "Missing bank code",
    ACCNUM_MISS: "Missing account number",
    ACCNAME_MISS: "Missing account name",
    TRXN_TIME_MISS: "Missing transaction time",
    TRXN_ID_MISS: "Missing transaction ID",
    AMOUNT_MISS: "Missing amount",
    RECID_MISS: "Missing receiver ID",
    GAMECODE_MISS: "Missing game code",

    FIELD_MISS: "Missing param field",
    FIELD_EMPTY: "Field Empty",
    FIELD_NUMERIC: "Field numeric only",

    //Database
    DB_ERROR: "DB_ERROR",
    UNKNOWN_ERROR: "UNKNOWN_ERROR",

    SMS_ADD_FAILED: "SMS_ADD_FAILED",
    PASS_UPDATE_FAILED: "PASS_UPDATE_FAILED",
    SIGNUP_FAILED: "SIGNUP_FAILED",
    PIN_UPDATE_FAILED: "PIN_UPDATE_FAILED",
    LOGIN_RECORD_UPDATE_FAILED: "LOGIN_RECORD_UPDATE_FAILED",
    GAME_TRXN_ADD_FAILED: "GAME_TRXN_ADD_FAILED",
    WALLET_INSERT_FAILED: "WALLET_INSERT_FAILED",
    //#endregion

    //#region #2

    // Wallet, Module
    REFCODE_INVALID: "Invalid referral code",
    COMPCODE_INVALID: "Invalid company code",
    PERMISSION_DENIED: "Permission denied",

    MOBILE_NOT_REGISTER: "Invalid mobile or password", // 'Mobile not registered',
    MOBILE_REGISTERED: "Mobile already registered",
    REF_NOT_EXIST: "Referral code not exist",
    DID_NOT_EXIST: "DeviceID not exist",
    DIFF_DID: "Please verify your mobile.\nOTP has been sent to you.",
    LOGIN_DIFF_DEVICE:
      "Session expired.\nThe account has been logged in another device.",

    // Profile
    BANK_ACCNAME_ADD_FAILED: "Failed to add bank account name",
    BANK_RECORD_ADD_FAILED: "Failed to add bank record",
    BANK_RECORD_UPDATE_FAILED: "Failed to update bank record",
    BANK_RECORD_DELETE_FAILED: "Failed to delete bank record",
    ACCOUNT_LOCK: "Account locked. Pls contact customer service",
    BANK_ACCOUNT_EXISTS: "Bank account already exist! Please add a new one.",

    // Changing Device
    DEVICE_CHANGE_LIMIT_REACHED: "Device change limit reached.",

    // Wallet Transaction
    TOPUP_ADD_FAILED: "Failed to add top up record",
    TOPUP_APPROVE_FAILED: "Failed to approve top up",
    WITHDRAW_ADD_FAILED: "Failed to add withdraw record",
    WITHDRAW_APPROVE_FAILED: "Failed to approve withdraw",
    TRANSFER_SEND_ADD_FAILED: "Failed to add transfer record (Sender)",
    TRANSFER_REC_ADD_FAILED: "Failed to add transfer record (Receiver)",
    TRANSFER_RECINFO_FAILED: "Failed to get receiver info",
    TRANSFER_CURRENCY_FAILED: "Failed to transfer (Invalid currency)",
    UPLOAD_PROCESS_FAILED: "Failed to proceed upload receipt",
    TICKET_SAVE_FAILED: "Failed to save ticket records",
    OTP_VERIFY_FAILED: "Invalid OTP",

    BALANCE_INSUFFICIENT: "Insufficient balance",
    NO_AVAIL_DIGIT: "Top Up is not available now.\nPlease try again later.",

    // File
    UPLOAD_ERROR: "Failed to upload receipt",
    FILE_ERROR: "Invalid file type (.png or .jpg)",

    // Backend - Withdraw
    WITHDRAW_PROGRESS: "Wtihdraw is in progress",

    // Promo
    PROMO_NO: "No promotion.",
    PROMO_CLAIMED: "Promotion already claimed.",
    PROMO_NO_MIN: "No Promotion. Min Topup is",
    PROMO_BANK_VERIFY: "Bank account verification still in progress.",
    PROMO_EXPIRED: "Angpao already expired",
    PROMO_ANGPAO_ERR: "Unable to add angpao or angpao already claimed.",
    PROMO_ANGPAO_NEGATIVE: "Please try again",

    // Mission
    MISSION_MISS: "Missing mission",
    MISSIONID_MISS: "Missing mission ID",
    REPEATABLE_MISS: "Missing repeatable",
    TARGET_MISS: "Missing target",
    REWARD_MISS: "Missing reward",

    MISSION_LIST_FAILED: "Unable to get mission list",
    RECORD_LIST_FAILED: "Unable to get record list",
    MISSION_NOT_FOUND: "Mission not found",
    MISSION_CLAIM_FAILED: "Unable to claim mission",
    MISSION_CLAIMED: "Mission already claimed",
    MISSION_ADD_COMP_FAILED: "Unable to add / complete mission",
    MISSION_ADDED_COMPED: "Mission already added / completed",
    MISSION_CHECK_FAILED: "Unable to check mission",
    MISSION_ARCHIVE_FAILED: "Unable to archive mission",

    //#endregion

    //#region #3

    // SMS, Game
    SMS_INSUFFICIENT: "OTP Error",
    SMS_SEND_FAILED: "Invalid mobile",
    OTP_SEND_FAILED: "OTP failed to send",
    OTP_NOT_EXIST: "OTP not exist",
    OTP_VERIFIED: "OTP has been verfied",

    // Game Integration
    GAME_UNKNOWN_ERROR: "Unknown Game Error",
    GAME_USERID_NOT_EXIST: "Game User ID not exist",
    GAME_MAINTENANCE: "Game is under maintenance",
    GAME_INVALID: "Invalid game",
    GAME_API_CATCH: "Game Api Catch Error",
    GAME_NETWORK_INTERRUPT: "Network interrupted. Unable to exit game",

    // Game API
    GAME_LOGIN_ERROR: "Game Login Error",
    GAME_CREATE_MEMBER_ERROR: "Game Create Member Error",
    GAME_BALANCE_ERROR: "Game Balance Error",
    GAME_DEPOSIT_ERROR: "Game Deposit Error",
    GAME_WITHDRAW_ERROR: "Game withdraw Error",
    GAME_BETLIMIT_ERROR: "Game Betlimit Error",
    GAME_DOWNLOAD_TICKET_ERROR: "Game Download Ticket Error",
    GAME_AMOUNT_ZERO: "Game Amount Zero Error",

    GAME_PLAY_ERROR: "Play Game Error",
    GAME_EXIT_ERROR: "Exit Game Error",
    GAME_API_ERROR: "Game API Error",
    //#endregion
  },

  sideMenu: {
    notification: "Notification",
    myProfile: "My Profile",
    myBank: "My Bank",
    statement: "Statement",
    allGames: "All Games",
    gamesConfig: "Games Config",
    gamesLog: "Games Log",
    promotionAndReward: "Promotion & Reward",
    vip: "VIP",
    affiliate: "Affiliate",
    applyPremiumAgent: "Apply Premium Agent",
    contactUs: "Contact Us",
    downloadApp: "Download App",
    feedback: "Feedback",
    theme: "Theme",
    logout: "Logout",
    openInDefaultBrowser: "Open In Default Browser",
    slot: "Slot Games",
    sport: "Sport Book",
    live: "Live Casino",
    fish: "Fish Games",
  },

  statement: {
    transaction: "Transaction",
    promotion: "Promotion",
    affiliate: "Affiliate",
    games: "Games",
  },

  intropage: {
    or: "or",
    hotGames: "PG Hot Games",
    featuredGames: `${clientInfo.appname} Featured`,
    newGames: "New Games",
    allGames: "All Games",

    registerBtn: "Register",
    loginBtn: "Login",
    downloadAppBtn: "Download App",
    moreBtn: "More",
    moreInfoBtn: "More",
    inviteBtn: "Invite",
    viewAllBtn: "View All",
    joinNowBtn: "Join Now",
    playBtn: "Play",
    goBtn: "GO",

    promotionHighlight: "Promotion Highlight",
    promotionHighlightItem1Title: "Fortune Wheel",
    promotionHighlightItem1SubTitle: "Up To 100% FREE!!!",
    promotionHighlightItem1Desc: "Turnover X1 Only",
    promotionHighlightItem1Info:
      "Just top up 100 baht upward right now to be eligible to spin the fortune wheel and win up to 100% Free Credit reward!!!",
    promotionHighlightItem2Title: "Unlimited Rebate",
    promotionHighlightItem2SubTitle: "Generated Everyday!!!",
    promotionHighlightItem2Desc: "0.3% Turnover Amount",
    promotionHighlightItem2Info:
      "Get a special rebate of 0.3% everyday unlimitedly for a lifetime. The more you play,the more you receive it back!!!",
    promotionHighlightItem3Title: "5% Rebate Bonus",
    promotionHighlightItem3SubTitle: "From Your loss!!!",
    promotionHighlightItem3Desc: "For Slot Only",
    promotionHighlightItem3Info:
      "Get a rebate 5% every day from your loss! The more you play,the more you get back!Hurry and top up to play your slot game now!!!",
    promotionHighlightItem4Title: "Get Free 50 baht",
    promotionHighlightItem4SubTitle: `On ${clientInfo.appname} Website!!!`,
    promotionHighlightItem4Desc: "Every Successful Invite",
    promotionHighlightItem4Info:
      "50 baht free credit reward will be credited to your main wallet account when your successful invite your friend to join!!!",

    affiliate: "Affiliate",
    affiliateInfo1Title: "1 Share Get 50 Baht",
    affiliateInfo1Desc: "Once Downline Deposit",
    affiliateInfo2Title: "Affiliate Commission 14%",
    affiliateInfo2Desc: "T1: 10% | T2: 3% | T3: 1%",
    affiliateReport: "Affiliate Report",
    premiumAffiliate: "Premium Affiliate",
    affiliateDownline: "Affiliate Downline",

    brandClub: `${clientInfo.appname} Club`,
    movieClub: "Movie Club",
    movieClubDesc:
      "Never bored, immerse\nin thousands of movies\nwhen you’re not playing.\nNew movies updated daily.",
    movieClubInfo1Title: "4,000++ Movie",
    movieClubInfo2Title: "4,000++ Adult Movie",

    downloadAppDesc: "Enjoy even more perks by downloading our APP.",

    aboutUsInfo: `At ${clientInfo.appname}, we’re committed to provide only original & authentic games certified by industry most recognised institution. To ensure every players can have a peace of mind by having a fair &  responsible gaming experiences`,
    licensesCertification: "Licenses & Certification",
    licensesCertificationInfo: "Authentic Games Guaranteed",
    joinOurCommunity: "Join Our Community",
    contactUs: "Contact Us",
    supportedPayment: "Supported Payment",
    paymentSupported: "Payment Supported",

    wallet: "Wallet",
    deposit: "Deposit",
    withdrawal: "Withdrawal",
    transcationRecords: "Transaction Records",
    gameLog: "Game Log",
    myProfile: "My Profile",
    myBank: "My Bank",
    promotion: "Promotion",
    bonus: "Bonus",
    mission: "Mission",
    rebate: "Rebate",

    vip: "VIP",
    vipClub: "VIP Club",
    vipBenefits: "Vip Benefits",
    vipTier: "Vip Tier",
    vipConcierge: "VIP Concierge",

    games: "Games",
    gamesID: "Games ID",
    slots: "Slots",
    casino: "Casino",
    fish: "Fish",
    sport: "Sport",
    lotto: "Lotto",
    sport: "Sport",
    appGames: "App Games",

    sport: "Sport",
    slotGames: "Slot Games",
    sportBook: "Sport Book",
    liveCasino: "Live Casino",
    fishGames: "Fish Games",
    gamesConfig: "Games Config",

    aboutUs: "About Us",

    copyright: `Copyright ${clientInfo.appname} @ ${clientInfo.currYear} All Rights Reserved`,
  },
  login: {
    rmbPw: "Remember Password",
    forgotPw: "Forgot Password",
  },
  button: {
    continue: "Continue",
    resendOtp: "Resend OTP",
  },
  registerBank: {
    title: "Register Your Bank Account",
  },
  setPassword: {
    title: "Set Your Password",
  },
  enterPassword: {
    title: "Enter Your Password",
  },
  trueWallet: {
    transferFrom: "Transfer From:",
    registerTitle: "Register Your True Wallet",
    registerDesc:
      "Kindly register your bank account to enable deposit & withdrawal function",
    enterDepositAmount: "Enter Deposit Amount (THB)",
    minDeposit: "Minimum Deposit ",
    minDepositAmt: "10 Baht",
    addedAccMsg: "Account added successfully",
    addAcc: "Add Account",
    editAcc: "Edit",
  },
  loginProhibition: {
    title: "Self Exclusion",
    desc: "Choose a time period to prevent yourself from logging back into our app.",
    successMsg: "Submitted successfully.",
  },
  dateUnit: {
    days_one: "{{dateUnit}} Day",
    days_other: "{{dateUnit}} Days",
    weeks_one: "{{dateUnit}} Week",
    weeks_other: "{{dateUnit}} Weeks",
    months_one: "{{dateUnit}} Month",
    months_other: "{{dateUnit}} Months",
  },
  security: {
    title: "2 Factor Authentication",
    googleAuthOpt: "Google 2FA Authentication",
    googleAuth: {
      setupTitle: "Set Up Google Authenticator",
      setupDesc_1: "Please install Google Authenticator app on your phone",
      setupDesc_2: "Please Scan the QR in Google Authenticator",
      setupDesc_3: "(Google Authenticator)",
      unbindTitle: "Unbind Google Authentication",
    },
  },
  captcha: {
    errMsg: "Please complete captcha to re-enter account no.",
    loginErrMsg: "Please complete captcha to proceed further.",
  },
};
