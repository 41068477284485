import axios from "axios";
import Loading from "../components/root/loading_spinner/Loading";
import Dialog from "../components/root/dialog/Dialog";
import { api } from "./Action";
import { store } from "../redux/Store";
import { balance, bank, logIn, token, wdrlBal } from "../redux/userSlice";
import i18n from "../../i18n";
import Bank from "../components/root/bank/Bank";
import { t as trans } from "i18next";
import { helper } from "../utils";
import { millisecondToTime } from "../utils/format";
import { clientInfo } from "../../resources";
import Anim from "../components/root/animation/Anim";

import { setCompany, setMarquee } from "../redux/companySlice";
import { isStag } from "../utils/envHelper";
import { setAnim } from "../redux/optionSlice";
import providerSlice from "../redux/game/providerSlice";
import { LoginAccount } from "../utils/loginHelper";
import { navigateHelper } from "../utils/navigateHelper";
import {
  REGISTERED_ACTION_KEY_SCRIPT,
  executeScriptFunction,
} from "../utils/pageSettingHelper";

// const api_path = '/api/rest';
const api_path = "/api/rest/ping";
const call_path = "/api/h5";

const domain = isStag ? clientInfo.stagingDomain : clientInfo.domains;

const start = axios.create({
  // timeout: 5000,
  method: "post",
});

// var networkErr = false;

// function delay(ms) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

start.interceptors.request.use(
  async (config) => {
    let baseURL = "";

    for (let i = 0; i < domain.length; i++) {
      let available = domain[i];
      // const check = await fetch(`${available}/api/rest/version`)
      const check = await fetch(`${available}${api_path}`)
        .then((res) => {
          return res.ok;
        })
        .catch((error) => {
          console.log("** Error: Domain not working **", error);
        });

      if (check) {
        baseURL = available;
        break;
      }
    }

    console.log("baseURL: ", baseURL);
    const { token, profile } = store.getState().user;

    config = {
      ...config,
      baseURL: `${baseURL}${call_path}`,
      // baseURL: `${baseURL}`,

      headers: {
        // Accept: 'application/json',
        // 'Content-Type': 'application/json; charset=utf-8',
        "Accept-Language": i18n.language,
        "x-token": token,
        "x-userid": profile.userId,
        ...config.headers,
      },
    };

    // Loading.show();
    return config;
  },
  (error) => {
    // Loading.show();
    return Promise.reject(error);
  }
);

start.interceptors.response.use((response) => {
  console.log(`=> Status Code `, response.status);
  // Loading.hide();

  if (response.status) {
    switch (response.status) {
      case 200:
        return response.data;
      case 201:
        return response.data;
      default:
        alert(response.data.msg);
    }
  } else {
    return response;
  }
});

async function reqApi(
  {
    act,
    data,
    success,
    error,
    cancelled,
    signal,
    cancelToken,
    headers,
    spreadData,
  },
  { dialog = true, spinner = false } = {}
) {
  if (spinner) {
    Loading.show();
  }
  return start({
    url: act,
    data: {
      // act: act,
      isEncrypt2161: "N",
      data: data,
      ...spreadData,
    },
    headers,
    cancelToken: cancelToken,
  })
    .then(async (json) => {
      if (spinner) {
        Loading.hide();
      }
      const { status, data, errcode, msg, "x-token": xToken } = json;
      const { token: curToken } = store.getState().user;

      console.log(`=> Action: `, act);
      console.log(`=> Status: `, status);
      console.log(`=> ErrCode: `, errcode);
      console.log(`=> Msg: `, msg);
      console.log(`=> Token: `, xToken);
      console.log(`=> Data: `, data);

      if (curToken !== xToken && xToken) {
        store.dispatch(token(xToken));
        sessionStorage.setItem("token", JSON.stringify(xToken));
      }

      if (errcode) {
        return Promise.reject({ response: { data: json } });
      }

      const isEmpty = (item) => {
        return Object.keys(item).length === 0;
      };

      if (success) success(isEmpty(data) ? { noData: json } : json);
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        console.log(`request being cancelled`);
        if (cancelled) cancelled(err);
        return;
      }
      if (spinner) {
        Loading.hide();
      }
      const { status, errcode, msg } = err.response?.data || {};

      console.log("=> Catch Err: ", err);
      console.log(`=> Catch Action: `, act);
      console.log(`=> Catch Status: `, status);
      console.log(`=> Catch ErrCode: `, errcode);
      console.log(`=> Catch Msg: `, msg);

      switch (errcode) {
        case "554":
          Dialog.show({
            show: true,
            msg: trans("dialog.err.depositMaintenance"),
            type: "error",
            btnAct: () => {
              navigateHelper(-1);
            },
          });
          break;

        case "444":
          Dialog.show({
            show: true,
            msg: trans("dialog.msg.sessionExp"),
            type: "error",
            btnAct: () => {
              sessionStorage.clear();
              window.location.reload();
            },
          });
          break;

        default:
          if (dialog) {
            Dialog.show({
              show: true,
              msg: errcode
                ? `${errcode}: ${msg}`
                : "Something went wrong, Please contact your administrator for help.",
              type: "error",
              btnAct: () => {
                // Loading.hide();
              },
            });
          }
          break;
      }

      if (error) error(err.response?.data);
    });
}

/**
 * @param {object} props
 * @param {string} props.mobileNumber
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function VerifyMobileNumber({ mobileNumber, success, error }) {
  return reqApi({
    act: api.VerifyMobileNumber,
    data: {
      mobileNumber: mobileNumber,
    },
    success: (res) => {
      // if (res.data) {
      //   localStorage.setItem('verified', res.data.userId);
      // }

      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {string} props.mobileNumber
 * @param {Boolean} props.autoLogin
 * @param {Boolean} props.showErrDialog
 * @param {Boolean} props.showSpinner
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function VerifyPassword({
  userId,
  password,
  autoLogin = true,
  showErrDialog = true,
  showSpinner = true,
  success,
  error,
}) {
  return reqApi(
    {
      act: api.VerifyPassword,
      data: {
        userId: userId,
        password: password,
      },
      success: (res) => {
        if (autoLogin) {
          LoginAccount(res.data);
        }

        AppInit({});
        // let windowId = 0;
        // const getWindowArray = () => {
        //   let storage = localStorage.getItem('checkTab');
        //   return storage ? JSON.parse(storage) : [];
        // };

        // const setWindowArray = (data) => {
        //   localStorage.setItem('checkTab', JSON.stringify(data));
        // };

        // let windowArray = getWindowArray();
        // console.log('windowArray: ', windowArray);

        // windowId = Date.now().toString();
        // const newWindowArray = [...windowArray, windowId];
        // setWindowArray(newWindowArray);

        if (success) success(res);
      },
      error: (err) => {
        if (error) error(err);
      },
    },
    { dialog: showErrDialog, spinner: showSpinner }
  );
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {string} props.mobileNumber
 * @param {Boolean} props.autoLogin
 * @param {Boolean} props.showErrDialog
 * @param {Boolean} props.showSpinner
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function UpdatePassword({
  userId,
  password,
  provider,
  gameGroup,
  username,
  autoLogin = true,
  showErrDialog = true,
  showSpinner = true,
  success,
  error,
}) {
  return reqApi(
    {
      act: api.UpdatePassword,
      data: {
        userId: userId,
        password: password,
        provider: provider,
        gameGroup: gameGroup,
        username: username,
      },
      success: (res) => {
        AppInit({});
        if (success) success(res);
      },
      error: (err) => {
        if (error) error(err);
      },
    },
    { dialog: showErrDialog, spinner: showSpinner }
  );
}

/**
 * @param {object} props
 * @param {string} props.mobileNumber
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function RequestOtp({ mobileNumber, check = "no", success, error }) {
  return reqApi(
    {
      act: api.RequestOpt,
      data: {
        mobileNumber: mobileNumber,
        check,
      },
      success: (res) => {
        if (success) success(res);
      },
      error: (err) => {
        if (error) error(err);
      },
    },
    { dialog: false }
  );
}

/**
 * @param {object} props
 * @param {string} props.mobileNumber
 * @param {string} props.otp
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function VerifyOtp({ mobileNumber, otp, success, error }) {
  return reqApi({
    act: api.VerifyOtp,
    data: {
      mobileNumber: mobileNumber,
      otp: otp,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.referrer
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function VerifyReferrer({ referrer, success, error, option }) {
  return reqApi(
    {
      act: api.VerifyReferrer,
      data: {
        referrer: referrer,
      },
      success: (res) => {
        if (success) success(res);
      },
      error: (err) => {
        if (error) error(err);
      },
    },
    option
  );
}

/**
 * @param {object} props
 * @param {string} props.mobileNumber
 * @param {string} props.referrer
 * @param {string} props.password
 * @param {object} props.bank
 * @param {string} props.bank.bankCode
 * @param {string} props.bank.accNum
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function SignUp({
  mobileNumber,
  referrer,
  password,
  lineUuid,
  bank,
  success,
  error,
  option,
}) {
  return reqApi(
    {
      act: api.SignUp,
      data: {
        mobileNumber: mobileNumber,
        referrer: referrer,
        password: password,
        bank,
        lineUuid,
      },
      success: (res) => {
        executeScriptFunction(REGISTERED_ACTION_KEY_SCRIPT);
        if (success) success(res);
      },
      error: (err) => {
        if (error) error(err);
      },
    },
    { option, spinner: true }
  );
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {('topup'| 'game' | 'withdraw' | 'mission')} props.type
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function GetUserRules({ type, success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.GetUserRules,
    data: {
      userId: profile.userId,
      type: type,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {number} props.sesh
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function CheckUserSession({ sesh, success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.CheckUserSession,
    data: {
      userId: profile.userId,
      sesh: sesh,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function AppInit({ success, error }) {
  return reqApi({
    act: api.AppInit,
    success: (res) => {
      const { isLogin } = store.getState().user;
      res.data.isLogin = isLogin;
      store.dispatch(setCompany(res.data));

      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function GameList({ success, error }) {
  return reqApi({
    act: api.GameList,
    success: (res) => {
      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {number} props.provider
 * @param {number} props.gameid
 * @param {number} props.gametype
 * @param {string} props.gameGroup
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function PlayGame({
  provider,
  gameid,
  gametype,
  platform,
  gameGroup,
  success,
  error,
}) {
  const { profile } = store.getState().user;

  return reqApi(
    {
      act: api.PlayGame,
      data: {
        userId: profile.userId,
        provider: provider,
        gameid: gameid,
        gametype: gametype,
        platform: platform,
        gameGroup: gameGroup,
      },
      success: (res) => {
        if (success) success(res);
      },
      error: (err) => {
        if (error) error(err);
      },
    },
    { spinner: true }
  );
}

/**
 * @param {object} props
 * @param {string} props.provider
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function GetAppGameUserInfo({ provider, success, error }) {
  const { profile } = store.getState().user;

  return reqApi(
    {
      act: api.GetAppGameInfo,
      data: {
        userId: profile.userId,
        provider: provider,
      },
      success: (res) => {
        if (success) success(res);
      },
      error: (err) => {
        if (error) error(err);
      },
    },
    { spinner: true }
  );
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {number} props.game
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function ExitGame({ game, success, error }) {
  const { profile } = store.getState().user;

  return reqApi(
    {
      act: api.ExitGame,
      data: {
        userId: profile.userId,
        game: game,
      },
      success: (res) => {
        if (success) success(res);
      },
      error: (err) => {
        if (error) error(err);
      },
    },
    { spinner: true }
  );
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function GetBalance({ success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.Balance,
    data: {
      userId: profile.userId,
    },
    success: (res) => {
      store.dispatch(balance(res.data));

      const prevData = JSON.parse(sessionStorage.getItem("logIn"));
      prevData.wallet.currbal = res.data.currbal;
      prevData.wallet.comm = res.data.comm;
      prevData.wallet.acctname = res.data.acctname;

      sessionStorage.setItem("logIn", JSON.stringify(prevData));

      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {function} props.navigate //internal
 * @param {function} props.noBankNavigate //internal
 * @param {function} props.verifyBankNav //internal
 * @param {function} props.pending //internal
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function UserBankList({
  closeBtn,
  noBankNavigate,
  navigate,
  verifyBankNav,
  success,
  error,
}) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.BankList,
    data: {
      userId: profile.userId,
    },
    success: (res) => {
      if (res.noData) {
        store.dispatch(bank(res.noData.data));
        helper.updateObj("logIn", { bank: res.noData.data });
        Bank.show({
          submitBtn: () => {
            Dialog.show({
              show: true,
              msg: trans("dialog.msg.verifyBank"),
              type: "success",
              btnAct: () => {
                if (verifyBankNav) verifyBankNav();
              },
            });
          },
          closeBtn,
        });

        if (noBankNavigate) {
          noBankNavigate();
        }
        return;
      }

      const isApproval = res.data.filter((item) => item.status === 1);

      store.dispatch(bank(isApproval));
      helper.updateObj("logIn", { bank: isApproval });

      const available = res.data.some((item) => {
        return item.status === 1;
      });

      const pending = res.data.some((item) => {
        return item.status === 5 || item.status === 7;
      });

      if (available) {
        if (navigate) navigate();
      } else if (pending) {
        Dialog.show({
          show: true,
          msg: trans("dialog.msg.verifyBank"),
          type: "success",
          btnAct: () => {
            if (verifyBankNav) verifyBankNav();
          },
        });
      }

      if (success) {
        success(res);
      }
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {function} props.navigate //internal
 * @param {function} props.pending //internal
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function UserInviteAngpao({ error }) {
  const { profile } = store.getState().user;
  var angpao = document.getElementById("invite-angpao");

  return reqApi({
    act: api.BankList,
    data: {
      userId: profile.userId,
    },
    success: (res) => {
      if (res.noData) {
        store.dispatch(bank(res.noData.data));
        helper.updateObj("logIn", { bank: res.noData.data });
        Bank.show({
          submitBtn: () => {
            angpao.style.display = "none";
            Anim.show({ verifiedBank: false, type: "invite" });
          },
        });

        return;
      }

      const isApproval = res.data.filter((item) => item.status === 1);

      store.dispatch(bank(isApproval));
      helper.updateObj("logIn", { bank: isApproval });

      const available = res.data.some((item) => {
        return item.status === 1;
      });

      const pending = res.data.some((item) => {
        return item.status === 5 || item.status === 7;
      });

      if (available) {
        angpao.style.display = "none";
        Anim.show({ verifiedBank: true, type: "invite" });
      } else if (pending) {
        angpao.style.display = "none";
        Anim.show({ verifiedBank: false, type: "invite" });
      }
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {string} props.bankCode
 * @param {string} props.accNum
 * @param {string} props.hCaptchaToken
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function AddBank({ bankCode, accNum, hCaptchaToken, success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.AddBankV2,
    data: {
      userId: profile.userId,
      bankCode: bankCode,
      accNum: accNum,
      hCaptchaToken: hCaptchaToken,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.bankCode
 * @param {string} props.accNum
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function TopupBank({ bankCode, accNum, success, error }) {
  const { profile } = store.getState().user;
  return reqApi(
    {
      act: api.TopupBank,
      data: {
        userId: profile.userId,
        accNum: accNum,
        bankCode: bankCode, //user bank code
      },
      success: (res) => {
        if (success) success(res);
      },
      error: (err) => {
        if (error) error(err);
      },
    },
    { dialog: false }
  );
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function TopupStart({ success, error }) {
  const { profile } = store.getState().user;

  return reqApi(
    {
      act: api.TopupStart,
      data: {
        userId: profile.userId,
      },
      success: (res) => {
        if (success) success(res);
      },
      error: (err) => {
        if (error) error(err);
      },
    },
    { spinner: true }
  );
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function TopupPending({ success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.TopupPending,
    data: {
      userId: profile.userId,
    },
    success: (res) => {
      if (res.data) {
        return success({
          topupDetail: res.data,
          time: millisecondToTime(res.data.time, 600),
        });
      }

      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {string} props.bankCode
 * @param {string} props.accNum
 * @param {string} props.accName
 * @param {string} props.userAccNum
 * @param {string} props.userAccName
 * @param {string} props.userBankCode
 * @param {string} props.amt
 * @param {string} props.discount
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function TopupRequest({
  bankCode,
  accNum,
  accName,
  userAccNum,
  userAccName,
  userBankCode,
  amt,
  discount,
  success,
  error,
}) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.TopupRequest,
    data: {
      userId: profile.userId,
      bankCode: bankCode,
      accNum: accNum,
      accName: accName,
      userAccNum: userAccNum,
      userAccName: userAccName,
      userBankCode: userBankCode,
      amt: amt,
      discount: discount,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.trxnId
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function TopupCancel({ trxnId, success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.TopupCancel,
    data: {
      userId: profile.userId,
      trxnId: trxnId,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {string} props.bankCode
 * @param {string} props.accNum
 * @param {string} props.accName
 * @param {string} props.amt
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function Withdraw2Bank({
  bankCode,
  accNum,
  accName,
  amt,
  success,
  error,
}) {
  const { profile } = store.getState().user;

  return reqApi(
    {
      act: api.Withdraw2Bank,
      data: {
        userId: profile.userId,
        bankCode: bankCode,
        accNum: accNum,
        accName: accName,
        amt: amt,
      },
      success: (res) => {
        if (success) success(res);
      },
      error: (err) => {
        if (error) error(err);
      },
    },
    { spinner: true }
  );
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {string} props.bankCode
 * @param {string} props.accNum
 * @param {string} props.accName
 * @param {string} props.comm
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function WithdrawComm2Bank({
  bankCode,
  accNum,
  accName,
  comm,
  success,
  error,
}) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.WithdrawComm2Bank,
    data: {
      userId: profile.userId,
      bankCode: bankCode,
      accNum: accNum,
      accName: accName,
      comm: comm,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {string} props.comm
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function WithdrawComm2Wallet({ comm, success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.WithdrawComm2Wallet,
    data: {
      userId: profile.userId,
      comm: comm,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function UserTurnover({ success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.UserTurnover,
    data: {
      userId: profile.userId,
    },
    success: (res) => {
      const { wallet } = store.getState().user;

      const withdrawalBal = wallet.currbal - res.data.turnover;
      store.dispatch(wdrlBal(withdrawalBal < 0 ? 0 : withdrawalBal));

      const prevData = JSON.parse(sessionStorage.getItem("logIn"));
      prevData.wallet.wdrlBal = withdrawalBal < 0 ? 0 : withdrawalBal;
      sessionStorage.setItem("logIn", JSON.stringify(prevData));

      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.password
 * @param {string} props.oldPassword
 * @param {boolean} props.showSpinner
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function ResetPassword({
  oldPassword,
  password,
  success,
  error,
  showSpinner = true,
}) {
  const { profile } = store.getState().user;

  return reqApi(
    {
      act: api.ResetPassword,
      data: {
        mobileNumber: profile.mobileNumber,
        password: password,
        oldPassword: oldPassword,
      },
      success: (res) => {
        if (success) success(res);
      },
      error: (err) => {
        if (error) error(err);
      },
    },
    { spinner: showSpinner }
  );
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function RecordWallet({ success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.RecordWallet,
    data: {
      userId: profile.userId,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function RecordGame({ success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.RecordGame,
    data: {
      userId: profile.userId,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function RecordComm({ success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.RecordComm,
    data: {
      userId: profile.userId,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function UserDownline({ success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.UserDownline,
    data: {
      userId: profile.userId,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function RecordRebateTotal({ success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.RecordRebateTotal,
    data: {
      userId: profile.userId,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function AppMarquee({ success, error }) {
  return reqApi({
    act: api.AppMarquee,
    success: (res) => {
      store.dispatch(setMarquee(res.data));
      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.password
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function ForgotPin({ mobileNumber, password, success, error }) {
  return reqApi({
    act: api.ForgotPin,
    data: {
      mobileNumber: mobileNumber,
      password: password,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function VerifyReferCode({ success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.VerifyReferCode,
    data: {
      userId: profile.userId,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {string} props.referCode
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function UpdateReferCode({ referCode, success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.UpdateReferCode,
    data: {
      userId: profile.userId,
      referCode: referCode,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function Notification({ success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.Notification,
    data: {
      userId: profile.userId,
      topic: clientInfo.notification,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (err) => {
      if (error) error(err);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.userId
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function MissionList({ spinner, success, error }) {
  const { profile } = store.getState().user;

  return reqApi(
    {
      act: api.MissionList,
      data: {
        userId: profile.userId,
      },
      success: (res) => {
        if (success) success(res);
      },
      error: (err) => {
        if (error) error(err);
      },
    },
    { spinner: spinner }
  );
}

/**
 * @param {object} props
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function NewsList({ success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.AppNews,
    data: {
      userId: profile.userId,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (res) => {
      if (error) error(res);
    },
  });
}

/**
 * @param {object} props
 * @param {number} props.rating
 * @param {string} props.remark
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function SendFeedback({ rating, remark, success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.AppFeedback,
    data: {
      userId: profile.userId,
      rating,
      remark,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (res) => {
      if (error) error(res);
    },
  });
}

/**
 * @param {object} props
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function SportList({ navigate, success, error }) {
  return reqApi({
    act: api.SportList,
    success: (res) => {
      navigate("/streaming", {
        state: {
          list: res.data.Match,
        },
      });
      if (success) success(res);
    },
    error: (res) => {
      if (error) error(res);
    },
  });
}

/**
 * @param {object} props
 * @param {string} props.channel
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function SportWatch({ channel, success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.SportWatch,
    data: {
      userId: profile.userId,
      channel,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (res) => {
      if (error) error(res);
    },
  });
}

/**
 * @param {object} props
 * @param {number} props.limit
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function PornList({ success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.PornList,
    data: {
      userId: profile.userId,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (res) => {
      if (error) error(res);
    },
  });
}

async function LineLogin({ lineUuid, success, error }) {
  return reqApi({
    act: api.LineLogin,
    data: {
      lineUuid,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (res) => {
      if (error) error(res);
    },
  });
}

async function LineUpdate({ lineUuid, mobile, success, error }) {
  return reqApi({
    act: api.LineUpdate,
    data: {
      lineUuid,
      mobile,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (res) => {
      if (error) error(res);
    },
  });
}

async function GetLineIDInfo({ success, error }) {
  return reqApi(
    {
      act: api.GetLineIDInfo,
      data: {},
      success: (res) => {
        if (success) success(res);
      },
      error: (res) => {
        if (error) error(res);
      },
    },
    { dialog: true, spinner: true }
  );
}

/**
 * @param {object} props
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function AffiliateComm({ success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.RecordAffiliateComm,
    data: {
      userId: profile.userId,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (res) => {
      if (error) error(res);
    },
  });
}

/**
 * @param {object} props
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function AffiliateCommSummary({ startDate, endDate, success, error }) {
  const { profile } = store.getState().user;

  return reqApi(
    {
      act: api.RecordAffiliateCommSummary,
      data: {
        userId: profile.userId,
        start: startDate,
        end: endDate,
      },
      success: (res) => {
        if (success) success(res);
      },
      error: (res) => {
        if (error) error(res);
      },
    },
    { spinner: true }
  );
}

/**
 * @param {object} props
 * @param {object} props.mission
 * @param {string} [props.apReward]
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function MissionClaim({ mission, apReward, success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.MissionClaim,
    data: {
      userId: profile.userId,
      mission,
      apReward,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (res) => {
      if (error) error(res);
    },
  });
}

/**
 * @param {object} props
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function PromoCheckFortuneWheel({
  next,
  success,
  error,
  check,
  navigate,
  noData,
}) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.PromoCheckFortuneWheel,
    data: {
      userId: profile.userId,
    },
    success: (res) => {
      if (res.noData) {
        if (noData) {
          noData();
        }

        if (typeof next === "function") next();
        return;
      }

      if (res.data.length > 0) {
        localStorage.setItem("FW", JSON.stringify(res.data));
      }

      const { bonus, bonusPecent, depositAmt, depositTime, position, type } =
        res.data[0];

      if (success) success(res);

      if (check) {
        if (res.data.length > 0) {
          Dialog.show({
            show: true,
            msg: trans("dialog.msg.claimReward"),
            type: "error",
            btnAct: () => {
              store.dispatch(setAnim(true));
              sessionStorage.setItem("anim", true);
              navigate("/");
            },
          });
        }
      } else {
        store.dispatch(setAnim(true));
        Anim.show({
          type: "wheel",
          props: {
            position: position,
            bonus: bonus,
            bonusPecent: bonusPecent,
            depositAmt: depositAmt,
            depositTime: depositTime,
            type: type,
            next,
          },
        });
      }

      if (typeof next === "function") next();
    },
    error: (res) => {
      if (error) error(res);
    },
  });
}

/**
 * @param {object} props
 * @param {string} [props.depositTime]
 * @param {number} [props.bonus]
 * @param {number} [props.depositAmt]
 * @param {number} [props.bonusPercent]
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function PromoClaimFortuneWheel({
  depositTime,
  bonus,
  depositAmt,
  bonusPercent,
  success,
  error,
}) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.PromoClaimFortuneWheel,
    data: {
      userId: profile.userId,
      depositTime,
      bonus,
      depositAmt,
      bonusPercent,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (res) => {
      if (error) error(res);
    },
  });
}

/**
 * @param {object} props
 * @param {string} [props.depositTime]
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function PromoUnClaimFortuneWheel({ depositTime, success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.PromoUnclaimFortuneWheel,
    data: {
      userId: profile.userId,
      depositTime,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (res) => {
      if (error) error(res);
    },
  });
}

/**
 * @param {object} props
 * @param {number} props.limit
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function MovieList({ success, error }) {
  const { profile } = store.getState().user;

  return reqApi({
    act: api.MovieList,
    data: {
      userId: profile.userId,
      limit: 100,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (res) => {
      if (error) error(res);
    },
  });
}

/**
 * @param {object} props
 * @param {string} [props.accNum]
 * @param {string} [props.accName]
 * @param {string} [props.amount]
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function TopUpTrueWallet({ accNum, accName, amount, success, error }) {
  const { profile } = store.getState().user;

  return reqApi(
    {
      act: api.TopUpTrueWallet,
      data: {
        userId: profile.userId,
        accNum: accNum,
        accName: accName,
        amt: amount,
      },
      success: (res) => {
        if (success) success(res);
      },
      error: (res) => {
        if (error) error(res);
      },
    },
    { dialog: true, spinner: true }
  );
}

/**
 * @param {object} props
 * @param {string} [props.name]
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function PageSetting({ name, success, error }) {
  return reqApi(
    {
      act: api.PageSetting,
      data: {
        name: name,
      },
      success: (res) => {
        if (success) success(res);
      },
      error: (res) => {
        if (error) error(res);
      },
    },
    { dialog: true, spinner: false }
  );
}

/**
 * @param {object} props
 * @param {string} props.bankCode
 * @param {string} props.accNum
 * @param {string} props.hCaptchaToken
 * @param {function} [props.success]
 * @param {function} [props.error]
 * @param {function} [props.cancelled]
 */
async function VerifyBank({
  bankCode,
  accNum,
  hCaptchaToken,
  success,
  error,
  cancelled,
  cancelToken,
}) {
  return reqApi(
    {
      act: api.VerifyBank,
      data: {
        bankCode,
        accNum,
        hCaptchaToken,
      },
      cancelToken: cancelToken,
      success: (res) => {
        if (success) success(res);
      },
      error: (err) => {
        if (error) error(err);
      },
      cancelled,
    },
    { dialog: false }
  );
}

/**
 * @param {object} props
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function TestingAPI({
  next,
  success,
  error,
  check,
  navigate,
  noData,
  cancelToken,
}) {
  const { profile } = store.getState().user;

  return reqApi({
    cancelToken: cancelToken,
    act: api.PromoCheckFortuneWheel,
    data: {
      userId: profile.userId,
    },
    success: (res) => {
      if (res.noData) {
        if (noData) {
          noData();
        }

        if (typeof next === "function") next();
        return;
      }

      if (res.data.length > 0) {
        localStorage.setItem("FW", JSON.stringify(res.data));
      }

      const { bonus, bonusPecent, depositAmt, depositTime, position, type } =
        res.data[0];

      if (success) success(res);

      if (check) {
        if (res.data.length > 0) {
          Dialog.show({
            show: true,
            msg: trans("dialog.msg.claimReward"),
            type: "error",
            btnAct: () => {
              store.dispatch(setAnim(true));
              sessionStorage.setItem("anim", true);
              navigate("/");
            },
          });
        }
      } else {
        store.dispatch(setAnim(true));
        Anim.show({
          type: "wheel",
          props: {
            position: position,
            bonus: bonus,
            bonusPecent: bonusPecent,
            depositAmt: depositAmt,
            depositTime: depositTime,
            type: type,
            next,
          },
        });
      }

      if (typeof next === "function") next();
    },
    error: (res) => {
      if (error) error(res);
    },
  });
}

/**
 * @param {object} props
 * @param {function} [props.success]
 * @param {function} [props.error]
 */

async function GetBlockedGames({ success, error }) {
  const { profile } = store.getState().user;

  return reqApi(
    {
      act: api.GetBlockedGames,
      data: {
        userId: profile.userId,
      },
      success: (res) => {
        if (success) success(res);
      },
      error: (res) => {
        if (error) error(res);
      },
    },
    { dialog: false, spinner: false }
  );
}

// /**
//  * @param {object} props
//  * @param {function} [props.duration]
//  * @param {function} [props.success]
//  * @param {function} [props.error]
//  */

// async function SetBanDuration({duration, success, error}) {
//   const {profile} = store.getState().user;

//   return reqApi(
//     {
//       act: api.banUser,
//       data: {
//         userId: profile.userId,
//         duration: duration,
//       },
//       success: (res) => {
//         if (success) success(res);
//       },
//       error: (res) => {
//         if (error) error(res);
//       },
//     },
//     {dialog: true, spinner: true},
//   );
// }

// /**
//  * @param {object} props
//  * @param {function} [props.success]
//  * @param {function} [props.error]
//  */

// async function GetGoogleAuthKey({success, error}) {
//   const {profile} = store.getState().user;

//   return reqApi(
//     {
//       act: api.getGoogleAuthKey,
//       data: {
//         userId: profile.userId,
//       },
//       success: (res) => {
//         if (success) success(res);
//       },
//       error: (res) => {
//         if (error) error(res);
//       },
//     },
//     {dialog: true, spinner: false},
//   );
// }

// /**
//  * @param {object} props
//  * @param {string} [props.token]
//  * @param {function} [props.success]
//  * @param {function} [props.error]
//  */

// async function VerifyGoogleAuth({token, success, error}) {
//   const {profile} = store.getState().user;

//   return reqApi(
//     {
//       act: api.verifyGoogleTwoFA,
//       data: {
//         userId: profile.userId,
//         token: token,
//       },
//       success: (res) => {
//         if (success) success(res);
//       },
//       error: (res) => {
//         if (error) error(res);
//       },
//     },
//     {dialog: true, spinner: true},
//   );
// }

// /**
//  * @param {object} props
//  * @param {string} [props.token]
//  * @param {string} [props.googleAuthStatus]
//  * @param {function} [props.success]
//  * @param {function} [props.error]
//  */

// async function UpdateGoogleAuthStatus({
//   token,
//   googleAuthStatus,
//   success,
//   error,
// }) {
//   const {profile} = store.getState().user;

//   return reqApi(
//     {
//       act: api.updateGoogleTwoFA_status,
//       data: {
//         userId: profile.userId,
//         enable: googleAuthStatus,
//         token: token,
//       },
//       success: (res) => {
//         store.dispatch(setEnableGoogleAuth(googleAuthStatus));
//         if (success) success(res);
//       },
//       error: (res) => {
//         if (error) error(res);
//       },
//     },
//     {dialog: true, spinner: true},
//   );
// }

async function MovieLists({ type, dateFrom, success, error }) {
  return reqApi({
    act: "https://ma.ogcapikiosk.site/movie/getMovie",
    spreadData: {
      Type: type,
      DateFrom: dateFrom,
    },
    headers: {
      "x-token": "686b769113193b3a4c968a5ad4f92e63",
      "x-userid": "5fe0cc5e-f5f9-4e8f-8b16-01099aab2e56",
      Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjlhYTU2N2YyNjE5MGUwYTUyYjM2OWVlODVlMTMyNmJhIiwidXNlcm5hbWUiOiJtb3ZpZUFwaSIsInNlY3JldCI6IjEiLCJyb2xlcyI6ImFkbWluIiwic2l0ZSI6Im1vdmllX2FwaSIsImlhdCI6MTcyNzM0MTk3NCwiZXhwIjoyMDQyOTE3OTc0fQ.8_79_9nNZZ1SEabL1zPXFJHj6_EMdozYiv2nE7uS7y0`,
    },
    success: (res) => {
      if (success) success(res);
    },
    error: (res) => {
      if (error) error(res);
    },
  });
}

// /**
//  * @param {object} props
//  * @param {function} [props.success]
//  * @param {function} [props.error]
//  */

// export let securityPageLoad_abort_key = 'SecurityPageLoad';
// async function SecurityPageLoad({success, error}) {
//   const {profile} = store.getState().user;
//   abortRequest(securityPageLoad_abort_key);

//   const abortController = new AbortController();
//   abortControllers[securityPageLoad_abort_key] = abortController;
//   return reqApi(
//     {
//       act: api.securityPageLoad,
//       data: {
//         userId: profile.userId,
//       },
//       signal: abortController.signal,
//       success: (res) => {
//         if (success) success(res);
//       },
//       error: (res) => {
//         if (error) error(res);
//       },
//     },
//     {dialog: true, spinner: false},
//   );
// }

export default Object.freeze({
  VerifyMobileNumber,
  VerifyPassword,
  RequestOtp,
  VerifyOtp,
  VerifyReferrer,
  SignUp,
  GetUserRules,
  CheckUserSession,
  AppInit,
  GameList,
  PlayGame,
  GetAppGameUserInfo,
  ExitGame,
  GetBalance,
  UserBankList,
  UserInviteAngpao,
  AddBank,
  TopupBank,
  TopupStart,
  TopupPending,
  TopupRequest,
  TopupCancel,
  Withdraw2Bank,
  WithdrawComm2Bank,
  WithdrawComm2Wallet,
  UserTurnover,
  ResetPassword,
  RecordWallet,
  RecordGame,
  RecordComm,
  RecordRebateTotal,
  UserDownline,
  AppMarquee,
  ForgotPin,
  VerifyReferCode,
  UpdateReferCode,
  Notification,
  MissionList,
  NewsList,
  SendFeedback,
  SportList,
  SportWatch,
  PornList,
  AffiliateComm,
  LineLogin,
  LineUpdate,
  AffiliateCommSummary,
  MissionClaim,
  PromoCheckFortuneWheel,
  PromoClaimFortuneWheel,
  PromoUnClaimFortuneWheel,
  MovieList,
  TopUpTrueWallet,
  PageSetting,
  VerifyBank,
  TestingAPI,
  GetBlockedGames,
  // SetBanDuration,
  // GetGoogleAuthKey,
  // VerifyGoogleAuth,
  // UpdateGoogleAuthStatus,
  // SecurityPageLoad,
  UpdatePassword,
  GetLineIDInfo,
  MovieLists,
});
