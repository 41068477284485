import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./Chat.scss";
import TopNav from "../modal/TopNav";
import CPButton from "../../common/components/button/CPButton";
import FlatList from "../../common/components/listing/FlatList";
import CustomerService from "../cs/CustomerService";
import { GetCommon, GetIntroBanner } from "../../resources/image/Image";
import CPListing from "../../common/components/listing/CPListing";
import { clientInfo } from "../../resources";
import api from "../../common/api";
import { isEqual } from "lodash";
import { useLocation } from "react-router-dom";
import ComponentSlider from "../../common/components/slider/ComponentSlider";
import { BRAND, useNav } from "../../common/utils/helper";
import { getReferral } from "../../common/utils/helper";

export default function Chat({ title }) {
  const location = useLocation();
  const pathname = location.pathname.replace("/", "");
  const chatLocalKey = "chatList";
  const { t: trans } = useTranslation();
  const { csLineId, refcodeExp } = useSelector(({ company }) => company);

  const [select, setSelect] = useState(pathname);
  const [info, setInfo] = useState([]);
  const [status, setStatus] = useState("");
  const Common = GetCommon();
  const IntroBanner = GetIntroBanner();
  let introImages = [];
  switch (BRAND) {
    case "bonanza99":
    case "tkbneko":
      introImages = [
        IntroBanner.introBanner1,
        IntroBanner.introBanner3,
        IntroBanner.introBanner4,
      ];
      break;
    default:
      introImages = [
        IntroBanner.introBanner1,
        IntroBanner.introBanner2,
        IntroBanner.introBanner3,
        IntroBanner.introBanner4,
      ];
      break;
  }
  const navigate = useNav();
  const refcode = getReferral(refcodeExp);

  useEffect(() => {
    setSelect(pathname);
  }, [pathname]);

  useEffect(() => {
    const listLocal = JSON.parse(localStorage.getItem(chatLocalKey));
    if (listLocal && isEqual(listLocal, info)) {
      return;
    }
    localStorage.setItem(chatLocalKey, JSON.stringify(info));
  }, [info]);

  useEffect(() => {
    if (select === "notification") {
      fetchNotificationData();
    }
  }, [select]);

  function fetchNotificationData() {
    api.Notification({
      success: (res) => {
        setInfo(res?.data || []);
        setStatus(res?.data ? "" : trans("general.list.noRecord"));
      },
      error: () => {
        setInfo([]);
        setStatus(trans("general.list.noRecord"));
      },
    });
  }

  function Tab(item) {
    let icon;
    let name;

    switch (item.item) {
      case "notification":
        icon =
          select === item.item ? Common.notification : Common.notification2;
        name = "Info";
        break;

      case "chat":
        icon = select === item.item ? Common.line5 : Common.line3;
        name = "LINE";
        break;

      default:
        break;
    }

    return (
      <div
        key={item.item}
        onClick={() => handleTabClick(item.item)}
        className={`chat-tab-btn ${
          select === item.item && "chat-tab-selected-bg"
        }`}
      >
        <img src={icon} className="chat-icon" alt="chat-icon" />
        <div
          className={`chat-name ${
            select === item.item && "chat-name-selected"
          }`}
        >
          {name}
        </div>
      </div>
    );
  }

  function handleTabClick(item) {
    setSelect(item);
    if (item === "chat") {
      navigate("/chat");
    } else if (item === "notification") {
      navigate("/notification");
    }
  }

  const handleLineButtonClick = () => {
    const newWindow = window.open("", "_blank");
    api.GetLineIDInfo({
      success: (res) => {
        let lineId = csLineId;
        if (res.data) {
          const matchedUser = res.data.find(
            (user) => user.ReferralCode === refcode
          );

          if (matchedUser && matchedUser.LineID !== "") {
            lineId = matchedUser.LineID;
          }
        }
        newWindow.location.href = `${clientInfo.lineURL}${lineId}`;
      },
      error: (err) => {
        console.log("error", err);
      },
    });
  };

  function Info() {
    return (
      <FlatList
        data={info}
        getStatus={status}
        containerStyle={"chat-render"}
        renderItem={(item, index) => (
          <CPListing
            key={index}
            listStyle={"chat-listing-style"}
            topLeftItem={<div className="info-title">{item.title}</div>}
            topRightItem={<div className="info-date">{item.datetime}</div>}
            bottomLeftItem={<div className="info-msg">{item.msg}</div>}
            listBottomRightContainer={"info-bottom-container"}
            bottomRightItem={<div>{""}</div>}
          />
        )}
      />
    );
  }

  function Line() {
    return (
      <div className="line-container">
        <div className="line-title"> {trans("chat.line.title")}</div>
        <CPButton
          btnBg={"line-btn"}
          title={trans("chat.line.btn")}
          textStyle={"line-btn-txt"}
          icon={Common.line2}
          iconStyle={"line-icon"}
          onClick={handleLineButtonClick}
        />
        <div className="intro-slider-container intro-width-container">
          <ComponentSlider
            autoLoopInterval={3000}
            showInnerIndicator={true}
            showBottomFaded={false}
          >
            {introImages.map((image, index) => (
              <div key={index} style={{ position: "relative", width: "100%" }}>
                <img src={image} alt={`Slide${index}`} draggable="false" />
              </div>
            ))}
          </ComponentSlider>
        </div>
      </div>
    );
  }

  return (
    <div className="chat-container container-padding">
      <div className="chat-style">
        <div className="chat-sticky-header">
          <div className="chat-tab-container">
            {["chat", "notification", clientInfo.cs && "Chat"]
              .filter(Boolean)
              .map((item) => (
                <Tab item={item} key={item} />
              ))}
          </div>
        </div>
        <div className="chat-content">
          {select === "chat" ? (
            <Line />
          ) : select === "notification" ? (
            <Info />
          ) : (
            clientInfo.cs && <CustomerService header={false} />
          )}
        </div>
      </div>
    </div>
  );
}
